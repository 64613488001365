const elementsWithDataVueApp = document.querySelectorAll('[data-vue-app]');

elementsWithDataVueApp.forEach(element => {
    const value = element.getAttribute('data-vue-app');

    const script = document.createElement('script');
    script.src = value;

    script.onload = () => {
        //console.log(`Script ${value} loaded successfully.`);
    };

    document.body.appendChild(script);
}); 